<template>
  <div class="skqcard">
    <div class="border">
      <div class="logo">
        <img src="@assets/img/skqlogo.png" />
      </div>
      <div class="title">操 作 账 号 卡</div>
      <van-cell-group>
        <van-cell title="账    号" :value="peodata.accoutname">
          <template #title>
            <div><span>账</span> <span>号:</span></div>
          </template>
        </van-cell>
        <van-cell title="LMSID" :value="peodata.empcode">
          <template #title>
            <div>
              <span>L</span>
              <span>M</span>
              <span>S</span>
              <span>I</span>
              <span>D:</span>
            </div>
          </template>
        </van-cell>
        <van-cell title="姓    名" :value="peodata.empname">
          <template #title>
            <div><span>姓</span> <span>名:</span></div>
          </template>
        </van-cell>
        <van-cell title="部    门" :value="peodata.deptname">
          <template #title>
            <div><span>部</span> <span>门:</span></div>
          </template>
        </van-cell>
        <van-cell title="部    门" :value="peodata.deptname" v-show="false">
          <template #title>
            <div><span>部</span> <span>门:</span></div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="ewm" v-if="peodata.accout_status == '正常'">
        <qrcode :text="QRCode" :width="200" :height="200" />
        <div class="yxq">有效曰期 :{{ today }}</div>
      </div>
      <div class="yichang" v-else>
        <img
          :src="
            require(isSuccess
              ? '@/assets/img/job_booking11.png'
              : '@/assets/img/job_booking10.png')
          "
          alt
        />
        <br />
        账号异常，请联系组长处理
      </div>
    </div>
  </div>
</template>

<script>
import qrcode from '@components/qrcode'
import { getReq, getDynV8ApiToken, getdecode } from '@api/wx.js'
import { Dialog, Toast, Notify } from 'vant'
import { parseTime } from '@/utils'
export default {
  components: {
    qrcode
  },
  data() {
    return {
      QRCode: 'tetetrt',
      v8apitoken: '',
      empid: '',
      peodata: {},
      today: parseTime(new Date(), '{y}-{m}-{d}')
    }
  },
  mounted() {
    this.ini()
  },
  methods: {
    ini() {
      this.empid = this.$route.query.empid
      getDynV8ApiToken().then(res => {
        // 获取v8apitoken 后面自定义接口用到
        this.v8apitoken = res
        this.getInfo()
      })
    },
    getInfo() {
      getReq(
        {
          autoid: 0,
          fixcode: '1a45fa5f5c2f4eea8780e4f0c571344e',
          p01: this.empid
        },
        this.v8apitoken
      ).then(res => {
        this.peodata = res.data[0]
        // this.QRCode = this.peodata.accoutname + '\n' + this.peodata.password
        this._getdecode()
      })
    },
    // 获取密码的明文
    _getdecode() {
      getdecode(
        {
          content: this.peodata.password,
          key: this.peodata.secretkey
        },
        this.v8apitoken
      ).then(res => {
        this.QRCode = this.peodata.accoutname + '\t' + res
        console.log(this.QRCode, 'this.QRCode')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.skqcard {
  padding: 0 5vw 0 5vw;
  height: 98vh;
  background: #fff url('~@assets/img/home_block_footer.png') bottom right
    no-repeat;
  //  background: rgb(255, 188, 65);
  margin-top: 2vh;

  .border {
    .logo {
      img {
        height: 7vh;
      }
    }
    background: rgb(255, 255, 255);
    border: 3px solid #999;
    border-radius: 20px;
    padding: 10px 10px 20px;
    .title {
      margin: 5vh 0 5vh;
      text-align: center;
      font-size: 90px;
      font-weight: bold;
    }
    .van-cell-group {
      background: none;
      padding: 0 10vw;
      .van-cell {
        background: none;
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
        .van-cell__title {
          font-weight: bold;
          div {
            display: flex;
            justify-content: space-between;
          }
        }
        .van-cell__value {
          text-align: center;
          flex: 3;
          color: #000;
          font-weight: bold;
        }
      }
      .van-cell::after {
        border-bottom-color: #000 !important;
        border-bottom-width: 10px;
        left: calc(25% + 30px);
      }
    }
    .ewm {
      margin: 5vh 0 5vh;
      .yxq {
        font-size: 40px;
        text-align: center;
        color: rgb(218, 0, 0);
      }
    }
    .yichang {
      margin: 5vh 0 5vh;
      height: 300px;
      font-size: 40px;
      text-align: center;
      color: rgb(218, 0, 0);
    }
    [class*='van-hairline']::after {
      display: none;
    }
  }
}
</style>
